import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DocumentService } from '../../services/document.service';
import { CartService } from '../../services/cart.service';

export interface DialogDataPolicy {
    policyData: null;
    policyName: null;
    privacy: false;
    portal: false;
    fromCookie: false;
    onlyRead: boolean | undefined;
}

@Component({
    selector: 'my-portal-dialog-policy',
    template: `

        <h2>{{data.policyName}}</h2>
        <mat-dialog-content *ngIf="this.typeSearch === 6 && data.fromCookie">
            <div [innerHTML]="termsPortal.policyData"></div>
        </mat-dialog-content>

        <h3 *ngIf="!hasSeenAllContent && !data?.onlyRead" class="read-terms-alert">Por favor, leia todo o conteúdo dos termos abaixo para habilitar o botão de "Li e aceito".</h3>

        <mat-dialog-content #dialogContentRef (scroll)="checkContentOnScroll($event)">
            <div *ngIf="!data.portal && !data.fromCookie">
                <div *ngFor="let hotel of cartHotel">
                    <div class="policies hotel-policy-{{hotel}}" *ngIf="!data.privacy && terms[hotel].policies.privacity">
                        <div class="title">{{ terms[hotel].hotel }}</div>
                        <div [innerHTML]="terms[hotel].policies.privacity?.content | sanitizeHtml"></div>
                    </div>
                    <div *ngIf="data.privacy" class="policies hotel-policy-{{hotel}}">
                        <div class="title">{{ terms[hotel].hotel }}</div>
                        <p>Check-in: {{ terms[hotel].policies.check_in }} | Check-out: {{ terms[hotel].policies.check_out }}</p>
                        <p [innerHTML]="terms[hotel].policies.children.description | sanitizeHtml"></p>
                        <h6>Política de Cancelamento</h6>
                        <div *ngFor="let cancelation of terms[hotel].policies.cancellation">
                            <p>
                                <strong>
                                    {{ cancelation.room_name }} -
                                    Período de: {{ cancelation.check_in | date: 'dd/MM/yyyy' }}
                                    {{ cancelation.check_out | date: 'dd/MM/yyyy' }}
                                </strong>
                            </p>
                            <div *ngIf="cancelation.policy" [innerHTML]="cancelation.policy.description"></div>
                        </div>

                        <h6>Contrato</h6>
                        <div *ngIf="terms[hotel].policies.contract" [innerHTML]="terms[hotel].policies.contract.description | sanitizeHtml"></div>
                    </div>
                </div>
            </div>

            <div *ngIf="data.fromCookie">
                <div *ngFor="let policy of terms">
                    <div class="policies" *ngIf="policy.policies.privacity">
                        <div class="title">{{ policy.hotel }}</div>
                        <div [innerHTML]="policy.policies.privacity?.content"></div>
                    </div>
                </div>
            </div>


            <div *ngIf="data.portal && !data.fromCookie">
                <div class="policies">
                    <div [innerHTML]="terms?.content"></div>
                </div>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button (click)="closePolicyDialog(false)">Fechar</button>
            <ng-container *ngIf="!data?.onlyRead">
                <button mat-button [disabled]="!hasSeenAllContent" (click)="closePolicyDialog(true)">Li e aceito</button>
            </ng-container>
        </mat-dialog-actions>
        `,
    styleUrls: ['./dialog-policy.component.scss']
})
export class DialogPolicyComponent implements OnInit, AfterViewInit {
    @ViewChild('dialogContentRef') dialogContentRef!: ElementRef;

    terms: any = null;
    termsPortal: any = null;
    cartHotel: any[] = [];
    typeSearch: number = Number(this.documentService.getWindow()?.sessionStorage.getItem('typeSearch'));
    hasSeenAllContent: boolean = false;

    constructor(public dialogRef: MatDialogRef<DialogPolicyComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogDataPolicy,
        private documentService: DocumentService,
        private cartService: CartService,) { }

    ngOnInit(): void {
        this.terms = this.data.policyData;
        this.termsPortal = this.data;
        this.cartService.currentDataCartItems.subscribe((data: any) => {
            if (data) {
                if (Object.keys(data.hotels).length) {
                    this.cartHotel = Object.keys(data.hotels);
                } else {
                    this.cartHotel = Object.keys(this.terms);
                }
            }
        });
    }

    ngAfterViewInit(): void {
        this.hasSeenAllContent = this.checkContent();
    }

    checkContent(): boolean {
        const contentHeight: number = this.dialogContentRef.nativeElement.scrollHeight;
        const containerHeight: number = this.dialogContentRef.nativeElement.clientHeight;

        return contentHeight <= containerHeight;
    }

    checkContentOnScroll(event: Event): void {
        if (this.hasSeenAllContent) {
            return;
        }

        const element = event.target as HTMLElement;

        if (element.scrollTop + element.clientHeight >= element.scrollHeight - 1) {
          this.hasSeenAllContent = true;
        }
    }

    closePolicyDialog(agreed: boolean): void {
        this.dialogRef.close(agreed);
    }

}
