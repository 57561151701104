import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {DialogPolicyComponent} from '../dialog-policy/dialog-policy.component';
import {MatDialog} from '@angular/material/dialog';
import {AppDataService} from '../../services/app-data.service';
import {DocumentService} from '../../services/document.service';
import { InitializerService } from '../../services/initializer.service';
import { Subscription } from 'rxjs';
import { CartService } from '../../services/cart.service';

@Component({
    selector: 'my-portal-terms',
    template: `
        <a *ngIf="showTerms" (click)="openTerms(true)">
            Termos e condições
        </a>`,
    styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit, OnDestroy {
    currentCartItemsSubscription$!: Subscription;

    items: any;
    policy: any;
    checked: boolean = false;
    showTerms: boolean = true;
    @Output() termsEvent = new EventEmitter();
    @Output() termsText = new EventEmitter();
    typeSearch: number = Number(this.documentService.getWindow()?.sessionStorage.getItem('typeSearch'));
    isLoadingPolicies: boolean = true;

    private currentUncheckPoliciesSubscription$!: Subscription;

    constructor(
        public dialog: MatDialog,
        private appService: AppDataService,
        private documentService: DocumentService,
        private cartService: CartService,
        private initializerService: InitializerService,
    ) { }


    ngOnInit(): void {
        this.currentUncheckPoliciesSubscription$ = this.initializerService.currentUncheckPolicies.subscribe((value: any) => {
            if (value && value.status === 'unchecked' && value.personId) {
                this.termsEvent.emit(false);
                this.checked = false;

                const personId = value.personId;
                const removeAt = value.removeAt;

                if (this.policy && this.policy[personId].policies?.cancellation?.length > 0) {
                    this.policy[personId].policies.cancellation.splice(removeAt, 1);
                }
            }
        });

        this.currentCartItemsSubscription$ = this.cartService.currentDataCartItems.subscribe((response: any) => {
            if (response) {
                this.items = response;
                this.getDataPolicy(this.generateObjForDataPolicy());
            }
        });

    }

    getDataPolicy(data?: any): void {
        this.appService.getDataPolicy(data)
            .subscribe((response: any) => {
                this.policy = (this.typeSearch < 6) ? response.data : response.data.hotels;
            });
    }

    generateObjForDataPolicy(): any {
        const obj: any = {};

        for (const hotel of Object.keys(this.items.hotels)) {
            obj[hotel] = {
                items: []
            };
            for (const itemCart of this.items.hotels[hotel].items) {
                obj[hotel].items.push({
                    tariff_id: itemCart.tarifa_id,
                    check_in: itemCart.check_in,
                    check_out: itemCart.check_out,
                });
            }
        }

        return obj;
    }

    openTerms(onlyRead: boolean): void {
        const dialogRef = this.dialog.open(DialogPolicyComponent, {
            data: {
                policyData: this.policy,
                policyName: 'Termos e Condições',
                privacy: true,
                onlyRead,
            },
            autoFocus: false,
            disableClose: true,
        });

        dialogRef.afterClosed().subscribe((hasSeenAllPolicyContent: boolean) => {
            if (hasSeenAllPolicyContent) {
                this.initializerService.changeUncheckPolicies({
                    status: 'checked',
                    personId: null,
                    removeAt: null
                });

                this.termsEvent.emit(true);

                setTimeout(() => {
                    if (this.typeSearch < 6) {
                        const el: HTMLElement | null | undefined = this.documentService.getWindow()?.document.querySelector('.policies');
                        this.termsText.emit(el?.innerHTML);
                    } else {
                        let policyTermsHtml: {[key: string]: string} = {}
                        for (let hotel of Object.keys(this.policy)) {
                            const el: HTMLElement | null | undefined = this.documentService.getWindow()?.document.querySelector(`.hotel-policy-${hotel}`);

                            if (el) {
                                policyTermsHtml[hotel] = el.innerHTML;
                            }
                        }
                        this.termsText.emit(policyTermsHtml);
                    }
                }, 50);
            } else {
                this.initializerService.changeUncheckPolicies({
                    status: 'unchecked',
                    personId: null,
                    removeAt: null
                });

                this.checked = false;
                this.termsEvent.emit(false);
            }
        });
    }


    ngOnDestroy(): void {
        if (this.currentUncheckPoliciesSubscription$) {
            this.currentUncheckPoliciesSubscription$.unsubscribe();
        }

        if (this.currentCartItemsSubscription$) {
            this.currentCartItemsSubscription$.unsubscribe();
        }
    }

}
